// extracted by mini-css-extract-plugin
export var backgroundColour = "FeaturedProject-module--backgroundColour--7fcec";
export var blue = "FeaturedProject-module--blue--9258e";
export var buttonLight = "FeaturedProject-module--buttonLight--5788e";
export var buttonMain = "FeaturedProject-module--buttonMain--f2cac";
export var buttonPlain = "FeaturedProject-module--buttonPlain--32383";
export var green = "FeaturedProject-module--green--cde3f";
export var imageSection = "FeaturedProject-module--imageSection--43d47";
export var purple = "FeaturedProject-module--purple--57842";
export var section = "FeaturedProject-module--section--95ae2";
export var textSection = "FeaturedProject-module--textSection--a0336";
export var textSectionDescription = "FeaturedProject-module--textSectionDescription--0aba9";
export var textSectionWrapper = "FeaturedProject-module--textSectionWrapper--ba6c1";